/*
 --------------------------------------------------
 --------------------------------------------------
     Table Name : LU_PermissionGroups
 --------------------------------------------------
 --------------------------------------------------
 DO NOT MODIFY MANUALLY
 --------------------------------------------------
 -------------------------------------------------- */

export const ADMINISTER_CALENDAR = 'Administer_Calendar' as const;
export const ADMINISTRATOR = 'Administrator' as const;
export const CENTER_SIGN_IN_MANAGE = 'CenterSignIn_Manage' as const;
export const COURSE_CONTRIBUTOR = 'Course_Contributor' as const;
export const COURSE_EDIT = 'CourseEdit' as const;
export const COURSE_MANAGE_PARTICIPANTS = 'CourseManageParticipants' as const;
export const COURSE_MONITOR = 'Course_Monitor' as const;
export const COURSE_OWNER = 'Course_Owner' as const;
export const COURSE_PARTICIPANT = 'Course_Participant' as const;
export const COURSE_PATIENT = 'Course_Patient' as const;
export const COURSE_PATIENT_MONITOR = 'Course_Patient_Monitor' as const;
export const COURSE_RESULTS_VIEW = 'CourseResultsView' as const;
export const COURSE_SESSION_DEBRIEF = 'CourseSessionDebrief' as const;
export const COURSE_SESSION_START_JOIN = 'CourseSessionStartJoin' as const;
export const COURSE_STATISTICS_REPORT_VIEW = 'Course_Statistics_Report_View' as const;
export const COURSE_TOTAL_GRADES_VIEW = 'Course_Total_Grades_View' as const;
export const COURSE_VIEWER = 'Course_Viewer' as const;
export const CREATE_COURSES = 'CreateCourses' as const;
export const CREATE_SCENARIOS = 'CreateScenarios' as const;
export const CREATE_SESSIONS = 'CreateSessions' as const;
export const DEBRIEF_SCENARIO_SESSIONS = 'DebriefScenarioSessions' as const;
export const EVALUATION_COMPLETE = 'Evaluation_Complete' as const;
export const EVALUATION_COMPLETED_VIEW = 'Evaluation_Completed_View' as const;
export const EVALUATION_EDIT_COMPLETE = 'Evaluation_EditComplete' as const;
export const EVALUATION_GRADES_VIEW = 'Evaluation_Grades_View' as const;
export const EVALUATION_PARTICIPANT_COMPLETED_VIEW = 'Evaluation_Participant_Completed_View' as const;
export const EVALUATION_TEMPLATE_FILL_COMPLETE = 'EvaluationTemplate_Fill_Complete' as const;
export const EVALUATION_TEMPLATE_FILL_EDIT_COMPLETE = 'EvaluationTemplate_Fill_EditComplete' as const;
export const INTERNAL_CONTROL_EXAMS = 'Internal_Control_Exams' as const;
export const INTERNAL_MONITOR_EXAM_SESSIONS = 'Internal_MonitorExamSessions' as const;
export const INTERNAL_RECORDING_ANNOTATION_OWNER = 'Internal_Recording_Annotation_Owner' as const;
export const INTERNAL_RESERVATION_OWNER = 'Internal_Reservation_Owner' as const;
export const INTERNAL_RESERVATION_PARTICIPANT = 'Internal_Reservation_Participant' as const;
export const INTERNAL_RESERVATION_REQUEST_OWNER = 'Internal_Reservation_Request_Owner' as const;
export const INTERNAL_USERS_MANAGER = 'Internal_Users_Manager' as const;
export const MANAGE_PARTICIPANTS = 'ManageParticipants' as const;
export const MANAGE_SPS = 'ManageSps' as const;
export const PARTICIPANT = 'Participant' as const;
export const REPORT_ACROSS_SYSTEM = 'ReportAcrossSystem' as const;
export const SCENARIO_CONTRIBUTOR = 'Scenario_Contributor' as const;
export const SCENARIO_CONTRIBUTOR_RESULTS_VIEW = 'Scenario_Contributor_Results_View' as const;
export const SCENARIO_COURSE_CONTRIBUTOR_RESULTS_VIEW = 'Scenario_Course_Contributor_Results_View' as const;
export const SCENARIO_COURSE_MONITOR = 'ScenarioCourseMonitor' as const;
export const SCENARIO_COURSE_OWNER = 'ScenarioCourseOwner' as const;
export const SCENARIO_COURSE_SESSION_START_JOIN = 'ScenarioCourseSessionStartJoin' as const;
export const SCENARIO_COURSE_VIEWER = 'ScenarioCourseViewer' as const;
export const SCENARIO_EDIT = 'ScenarioEdit' as const;
export const SCENARIO_OWNER = 'Scenario_Owner' as const;
export const SCENARIO_PATIENT = 'Scenario_Patient' as const;
export const SCENARIO_START_SESSION = 'ScenarioStartSession' as const;
export const SCENARIO_VIEWER = 'Scenario_Viewer' as const;
export const SCORING_EVALUATION_EDIT_COMPLETE = 'Scoring_Evaluation_EditComplete' as const;
export const SCORING_RUBRIC_FILL_EDIT_COMPLETE = 'Scoring_Rubric_Fill_EditComplete' as const;
export const SESSION_ANNOTATE = 'SessionAnnotate' as const;
export const SESSION_CONTRIBUTOR = 'Session_Contributor' as const;
export const SESSION_CONTRIBUTOR_RESULTS_VIEW = 'Session_Contributor_Results_View' as const;
export const SESSION_COURSE_CONTRIBUTOR = 'Session_Course_Contributor' as const;
export const SESSION_COURSE_CONTRIBUTOR_RESULTS_VIEW = 'Session_Course_Contributor_Results_View' as const;
export const SESSION_COURSE_VIEWER = 'Session_Course_Viewer' as const;
export const SESSION_EDIT = 'SessionEdit' as const;
export const SESSION_OWNER = 'Session_Owner' as const;
export const SESSION_PARTICIPANT = 'Session_Participant' as const;
export const SESSION_PARTICIPANT_ANNOTATE = 'SessionParticipantAnnotate' as const;
export const SESSION_PARTICIPANT_RECORDING_ANNOTATIONS = 'SessionParticipantRecordingAnnotations' as const;
export const SESSION_PATIENT = 'Session_Patient' as const;
export const SESSION_PATIENT_ANNOTATE = 'Session_Patient_Annotate' as const;
export const SESSION_PATIENT_VIEW_ALL_ANNOTATIONS = 'Session_Patient_View_All_Annotations' as const;
export const SESSION_PATIENT_VIEW_SESSION = 'Session_Patient_View_Session' as const;
export const SESSION_RECORDING_ANNOTATIONS = 'SessionRecordingAnnotations' as const;
export const SESSION_RELEASE_VIEW = 'Session_Release_View' as const;
export const SESSION_SCENARIO_CONTRIBUTOR = 'Session_Scenario_Contributor' as const;
export const SESSION_SCENARIO_CONTRIBUTOR_RESULTS_VIEW = 'Session_Scenario_Contributor_Results_View' as const;
export const SESSION_SCENARIO_VIEWER = 'Session_Scenario_Viewer' as const;
export const SESSION_VIEWER = 'Session_Viewer' as const;
export const SESSIONS_CONTRIBUTOR_EVALUATION_CREATE = 'Sessions_Contributor_Evaluation_Create' as const;
export const START_ANY_SESSION = 'StartAnySession' as const;
export const SYSTEM_ADMIN = 'SystemAdmin' as const;

const LU_PermissionGroups = {
    ADMINISTER_CALENDAR,
    ADMINISTRATOR,
    CENTER_SIGN_IN_MANAGE,
    COURSE_CONTRIBUTOR,
    COURSE_EDIT,
    COURSE_MANAGE_PARTICIPANTS,
    COURSE_MONITOR,
    COURSE_OWNER,
    COURSE_PARTICIPANT,
    COURSE_PATIENT,
    COURSE_PATIENT_MONITOR,
    COURSE_RESULTS_VIEW,
    COURSE_SESSION_DEBRIEF,
    COURSE_SESSION_START_JOIN,
    COURSE_STATISTICS_REPORT_VIEW,
    COURSE_TOTAL_GRADES_VIEW,
    COURSE_VIEWER,
    CREATE_COURSES,
    CREATE_SCENARIOS,
    CREATE_SESSIONS,
    DEBRIEF_SCENARIO_SESSIONS,
    EVALUATION_COMPLETE,
    EVALUATION_COMPLETED_VIEW,
    EVALUATION_EDIT_COMPLETE,
    EVALUATION_GRADES_VIEW,
    EVALUATION_PARTICIPANT_COMPLETED_VIEW,
    EVALUATION_TEMPLATE_FILL_COMPLETE,
    EVALUATION_TEMPLATE_FILL_EDIT_COMPLETE,
    INTERNAL_CONTROL_EXAMS,
    INTERNAL_MONITOR_EXAM_SESSIONS,
    INTERNAL_RECORDING_ANNOTATION_OWNER,
    INTERNAL_RESERVATION_OWNER,
    INTERNAL_RESERVATION_PARTICIPANT,
    INTERNAL_RESERVATION_REQUEST_OWNER,
    INTERNAL_USERS_MANAGER,
    MANAGE_PARTICIPANTS,
    MANAGE_SPS,
    PARTICIPANT,
    REPORT_ACROSS_SYSTEM,
    SCENARIO_CONTRIBUTOR,
    SCENARIO_CONTRIBUTOR_RESULTS_VIEW,
    SCENARIO_COURSE_CONTRIBUTOR_RESULTS_VIEW,
    SCENARIO_COURSE_MONITOR,
    SCENARIO_COURSE_OWNER,
    SCENARIO_COURSE_SESSION_START_JOIN,
    SCENARIO_COURSE_VIEWER,
    SCENARIO_EDIT,
    SCENARIO_OWNER,
    SCENARIO_PATIENT,
    SCENARIO_START_SESSION,
    SCENARIO_VIEWER,
    SCORING_EVALUATION_EDIT_COMPLETE,
    SCORING_RUBRIC_FILL_EDIT_COMPLETE,
    SESSION_ANNOTATE,
    SESSION_CONTRIBUTOR,
    SESSION_CONTRIBUTOR_RESULTS_VIEW,
    SESSION_COURSE_CONTRIBUTOR,
    SESSION_COURSE_CONTRIBUTOR_RESULTS_VIEW,
    SESSION_COURSE_VIEWER,
    SESSION_EDIT,
    SESSION_OWNER,
    SESSION_PARTICIPANT,
    SESSION_PARTICIPANT_ANNOTATE,
    SESSION_PARTICIPANT_RECORDING_ANNOTATIONS,
    SESSION_PATIENT,
    SESSION_PATIENT_ANNOTATE,
    SESSION_PATIENT_VIEW_ALL_ANNOTATIONS,
    SESSION_PATIENT_VIEW_SESSION,
    SESSION_RECORDING_ANNOTATIONS,
    SESSION_RELEASE_VIEW,
    SESSION_SCENARIO_CONTRIBUTOR,
    SESSION_SCENARIO_CONTRIBUTOR_RESULTS_VIEW,
    SESSION_SCENARIO_VIEWER,
    SESSION_VIEWER,
    SESSIONS_CONTRIBUTOR_EVALUATION_CREATE,
    START_ANY_SESSION,
    SYSTEM_ADMIN
} as const;

export type PermissionGroup = typeof LU_PermissionGroups[keyof typeof LU_PermissionGroups];

export default LU_PermissionGroups;
